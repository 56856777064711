import { getBep20Contract } from 'utils/contractHelpers'
import tokens from 'config/constants/tokens'
import lendingTokens from 'config/constants/lending'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'

// fetches the balance of each reserve asset in the lending pool
export const fetchReserveData = async () => {
  const arrayOfCalls = []

  for (let i = 0; i < lendingTokens.length; i++) {
    const contract = getBep20Contract(lendingTokens[i].address)
    arrayOfCalls.push(contract.totalSupply())
  }
  try {
    const totalReservesInEth = await Promise.all(arrayOfCalls)
    const reserveData = []
    for (let i = 0; i < lendingTokens.length; i++) {
      const reserveBalanceAsBigNumber = totalReservesInEth[i] ? new BigNumber(totalReservesInEth[i].toString()) : BIG_ZERO
      if (reserveBalanceAsBigNumber.gt(0)) {
        const reserveEntry = { symbol: lendingTokens[i].symbol, reserveBalance: reserveBalanceAsBigNumber.toJSON() }
        reserveData.push(reserveEntry)
      }
    }

    return {
      reserves: reserveData,
      reserveDataLoaded: true,
    }
  } catch (error) {
    return {
      reserves: null,
      reserveDataLoaded: false,
    }
  }
}