import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { CoreDataState, AppThunk } from 'state/types'
import { fetchCoreData } from './fetchCoreData'

const initialState: CoreDataState = {
  totalCharm: null,
  burnedCharm: null,
  burnedKarma: null,
  charmKarmaRatio: null,
  karmaCirculatingSupply: null,
  burnFarmEarnings: null,
  userDataLoaded: false,
}

// Thunks
export const fetchCoreDataAsync = (): AppThunk =>
  async (dispatch) => {
    const coreData = await fetchCoreData()
    dispatch(setCoreData(coreData))
  }

export const CoreDataSlice = createSlice({
  name: 'coredata',
  initialState,
  reducers: {
    setCoreData: (state, action) => {
      state.totalCharm = action.payload.totalCharm
      state.burnedCharm = action.payload.charmBurned
      state.burnedKarma = action.payload.karmaBurned
      state.charmKarmaRatio = action.payload.charmKarmaRatio
      state.karmaCirculatingSupply = action.payload.karmaCirculatingSupply
      state.burnFarmEarnings = action.payload.burnFarmEarnings      
      state.userDataLoaded = true
    },
  },
})

// Actions
export const { setCoreData } = CoreDataSlice.actions

export default CoreDataSlice.reducer
