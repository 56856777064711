export default {
  masterChef: {
    40: '0x79f5A8BD0d6a00A41EA62cdA426CEf0115117a61',
  },
  sousChef: {
    40: '0xed9aDDa9B5722E76c89D186530161eeaa5BBA46d', // TODO: Replace temp address with a normal one.
  },
  burnAddress: {
    40: '0x000000000000000000000000000000000000dEaD', // TODO: Replace temp address with a normal one.
  },
  lotteryV2: {
    40: '0x0000000000000000000000000000000000000001', // TODO: Replace temp address with a normal one.
  },
  multiCall: {
    40: '0x74D01B798F0aEdc39548D3EA5fC922B291293b95',
  },
  pancakeProfile: {
    40: '0x0000000000000000000000000000000000000003', // TODO: Replace temp address with a normal one.
  },
  pancakeRabbits: {
    40: '0x0000000000000000000000000000000000000004', // TODO: Replace temp address with a normal one.
  },
  bunnyFactory: {
    40: '0x0000000000000000000000000000000000000005', // TODO: Replace temp address with a normal one.
  },
  claimRefund: {
    40: '0x0000000000000000000000000000000000000006', // TODO: Replace temp address with a normal one.
  },
  pointCenterIfo: {
    40: '0x0000000000000000000000000000000000000007', // TODO: Replace temp address with a normal one.
  },
  bunnySpecial: {
    40: '0x0000000000000000000000000000000000000008', // TODO: Replace  address with a normal one.
  },
  tradingCompetition: {
    40: '0x0000000000000000000000000000000000000009', // TODO: Replace temp address with a normal one.
  },
  easterNft: {
    40: '0x0000000000000000000000000000000000000010', // TODO: Replace temp address with a normal one.
  },
  charmVault: {
    40: '0x1482117c5F3F6962429C40068e22Cf4120bae94b',
  },
  dojoVault: {
    40: '0x41c98Ba90f8f5555C269d3Fb63f3B8E5033d75aA',
  },
  dojo: {
    40: '0x730d2Fa7dC7642E041bcE231E85b39e9bF4a6a64',
  },
  stlos: {
    40: '0xB4B01216a5Bc8F1C8A33CD990A1239030E60C905',
  },
  tlosescrow: {
    40: '0x95F5713A1422Aa3FBD3DCB8D553945C128ee3855',
  },
  LendingPool : {
    40: '0x6eC35d6B345DF1FAdBD3E3B2A8C4c4CAe84A5E26',
  },
  LendingPoolAddressesProvider : {
    40: '0x87F27B0DEE1Fd97A60dD5e5436c8068b805770E4',
  },
  OmniDexProtocolDataProvider : {
    40: '0x1D59555398c5dDbE98A7BcfE572D3597F24eE969',
  },
  olpManager: {
    40: '0x1B4Bd4C1a7e5Ca8E7278615d55886907961E46b8',
  },
  predictions: {
    40: '0x0000000000000000000000000000000000000012', // TODO: Replace temp address with a normal one.
  },
  chainlinkOracle: {
    40: '0x0000000000000000000000000000000000000013', // TODO: Replace temp address with a normal one.
  },
  bunnySpecialCakeVault: {
    40: '0x0000000000000000000000000000000000000014', // TODO: Replace temp address with a normal one.
  },
  bunnySpecialPrediction: {
    40: '0x0000000000000000000000000000000000000015', // TODO: Replace temp address with a normal one.
  },
  farmAuction: {
    40: '0x0000000000000000000000000000000000000016', // TODO: Replace temp address with a normal one.
  },
  burnFarmOwner: {
    40: '0x9892f867f0e3d54cf9eda66cf5886bd84d973e2f', // wallet that owns the LP tokens for the burn farm.
  },
}
