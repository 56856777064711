import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OmniTradeDataState, AppThunk } from 'state/types'
import { fetchOlpPrice }  from './fetchOmniTradeData'


const initialState: OmniTradeDataState = {
  olpPrice: null,
  omniTradeDataLoaded: false,
}

// Thunks
export const fetchOmniTradeDataAsync = (): AppThunk =>
  async (dispatch) => {    
    const latestPrice = await fetchOlpPrice()    
    dispatch(setOlpPrice(latestPrice))
  }

export const OmniTradeSlice = createSlice({
  name: 'omnitradedata',
  initialState,
  reducers: {
    setOlpPrice: (state, action) => {
      state.olpPrice = action.payload.olpPrice   
      state.omniTradeDataLoaded = !action.payload.isLoading
    },
  },
})

// Actions
export const { setOlpPrice } = OmniTradeSlice.actions

export default OmniTradeSlice.reducer
