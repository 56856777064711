import { getCakeContract, getDojoContract, getMasterchefContract } from 'utils/contractHelpers'  
import { getBurnAddress, getMasterChefAddress, getBurnFarmOwnerAddress } from 'utils/addressHelpers'
import { burnFarm } from 'config/constants/farms'
import masterchefABI from 'config/abi/ZenMaster.json'
import multicall from 'utils/multicall'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'

export const fetchCoreData = async () => {
  try {
    const charmContract = getCakeContract()        
    const zenMasterContract = getMasterchefContract()
    const dojoContract = getDojoContract() 
    const burnAddress = getBurnAddress() 
    const burnFarmOwner = getBurnFarmOwnerAddress()
    const oneShare = new BigNumber(1000000000000000000).toJSON() 
    const totalCharmMinted = await charmContract.totalSupply()
    const charmBurnBalance = await charmContract.balanceOf(burnAddress) // get amount of charm that has been burnt
    const karmaBurnBalance = await dojoContract.balanceOf(burnAddress) // get amount of karma that has been burnt     
    const karmaCharmRatio = await dojoContract.karmaForCharm(oneShare)      
    const totalKarmaMinted = await dojoContract.totalSupply() // get amount of karma un circulation
    const rawBurnPoolValue = await zenMasterContract.pendingCharm(burnFarm,burnFarmOwner)   // NOTE 21 is the PID for the Burn Pool
        
    const charmKarmaRatioAsBigNumber = karmaCharmRatio ? new BigNumber(karmaCharmRatio.toString()) : BIG_ZERO
    const totalCharmAsBigNumber = totalCharmMinted ? new BigNumber(totalCharmMinted.toString()) : BIG_ZERO
    const charmBurnBalanceAsBigNumber = charmBurnBalance ? new BigNumber(charmBurnBalance.toString()) : BIG_ZERO  
    const totalKarmaMintedAsBigNumber = totalKarmaMinted ? new BigNumber(totalKarmaMinted.toString()) : BIG_ZERO 
    const karmaBurnBalanceAsBigNumber = karmaBurnBalance ? new BigNumber(karmaBurnBalance.toString()) : BIG_ZERO  
    const karmaCirculatingSupply = totalKarmaMintedAsBigNumber.minus(karmaBurnBalanceAsBigNumber)
    const burnFarmEarningsAsBigNumber = rawBurnPoolValue ? new BigNumber(rawBurnPoolValue.toString()) : BIG_ZERO  
    
    return { 
      totalCharm: totalCharmAsBigNumber.toJSON(),
      charmBurned: charmBurnBalanceAsBigNumber.toJSON(), 
      karmaBurned: karmaBurnBalanceAsBigNumber.toJSON(), 
      charmKarmaRatio: charmKarmaRatioAsBigNumber.toJSON(),
      karmaCirculatingSupply: karmaCirculatingSupply.toJSON(),
      burnFarmEarnings: burnFarmEarningsAsBigNumber.toJSON(),
    } 
  } catch (error) {
    return {
      totalCharm: null,
      charmBurned: null,
    }
  }
}