//  This file shold ONLY cotain tokens that are not included in token.ts
// These are tokens that are not used in the OmniDex protocol but are reported in the user portfolio
import { ChainId, Token } from '../../sdk'


const tradingtokens = {
  olp: {
    symbol: 'OLP',
    address: {
      40: '0x207a1491aD1DF38c76F1Dc3770e1F0EBB77768c0',
    },
    decimals: 18,
    projectLink: 'https://omnidex.finance/',
  },
  escharm: {
    symbol: 'ESCHARM',
    address: {
      40: '0xCE874A4333b9B5bBC3986Eb73C382A01330C8CaE',
    },
    decimals: 18,
    projectLink: 'https://omnidex.finance/',
  }, 
  sbfcharm: {
    symbol: 'SBFCHARM',
    address: {
      40: '0x6592578F00bf088ba357C721D342eD030ED51a99',
    },
    decimals: 18,
    projectLink: 'https://omnidex.finance/',
  }, 
}


export default tradingtokens