import { MenuItemsType, DropdownMenuItemType  } from 'pancakeswap-uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    icon: 'Trade',
    href: '/perptrade',
    showItemsOnMobile: true,
    items: [
      {
        label: t('Trade'),
        href: '/trade',
       },
       {
        label: t('Dashboard'),
        href: '/tradedash',
       },
    ],
  },
  {
    label: t('Swap'),
    href: '/trade',
    icon: 'Trade',
    showItemsOnMobile: true,
    items: [
      {
        label: t('Swaps V2'),
        href: '/perptrade',
       },
       {
        label: t('Classic Swaps'),
        href: '/swap',        
       },
    ],
  },
  {
    label: t('Lend'),
    href: '/Markets',
    icon: 'Sell',
    showItemsOnMobile: true,
    items: [
      {
        label: t('Lending Markets'),
        href: '/markets',
       },
       {
        label: t('Lending Dashboard'),
        href: '/dashboard',        
       },
    ],
  },

  {
    label: t('Earn'),
    href: '/earn',
    icon: 'Account',
    showItemsOnMobile: true,
    items: [
      {
        label: t('Staking'),
        href: '/earn',
       },
       {
        label: t('Liquidity & Farming'),
        href: '/farms',        
       },
       {
        label: t('TLOS Staking'),
        href: '/tlospool',        
       },
    ],
  },

  {
    label: t('Portfolio'),
    href: '/Portfolio',
    icon: 'Account',
    showItemsOnMobile: false,
    items: [
    ],
  },
  {
    label: t('Stats'),
    href: '/tradingstats',
    icon: 'Account',
    showItemsOnMobile: false,
    items: [
    ],
  },

  {
    label: t('More'),
    href: '/ecosystem',
    icon: 'Account',
    showItemsOnMobile: true,
    items: [
      {
        label: t('Ecosystem'),
        href: '/ecosystem',
       },
       {
        label: t('Referrals'),
        href: '/referrals',        
       },
       {
        label: t('OmnidexBP'),
        href: '/omnidexbp',        
       },
       {
        label: t('About'),
        href: '/docs',        
       },
    ],
  },

  // {
  //   label: t('Win'),
  //   href: '/prediction',
  //   icon: 'Trophy',
  //   items: [
  //     {
  //       label: t('Prediction (BETA)'),
  //       href: '/prediction',
  //     },
  //     {
  //       label: t('Lottery'),
  //       href: '/lottery',
  //     },
  //   ],
  // },
  // {
  //   label: t('NFT'),
  //   href: `${nftsBaseUrl}`,
  //   icon: 'Nft',
  //   items: [
  //     {
  //       label: t('Overview'),
  //       href: `${nftsBaseUrl}`,
  //       status: {
  //         text: t('Live'),
  //         color: 'failure',
  //       },
  //     },
  //     {
  //       label: t('Collections'),
  //       href: `${nftsBaseUrl}/collections`,
  //     },
  //   ],
  // },
  // {
  //   label: '',
  //   href: '/info',
  //   icon: 'More',
  //   hideSubNav: true,
  //   items: [
  //     {
  //       label: t('Info'),
  //       href: '/info',
  //     },
  //     {
  //       label: t('IFO'),
  //       href: '/ifo',
  //     },
  //     {
  //       label: t('Voting'),
  //       href: '/voting',
  //     },
  //     {
  //       type: DropdownMenuItemType.DIVIDER,
  //     },
  //     {
  //       label: t('Leaderboard'),
  //       href: '/teams',
  //     },
  //     {
  //       type: DropdownMenuItemType.DIVIDER,
  //     },
  //     {
  //       label: t('Blog'),
  //       href: 'https://pancakeswap.medium.com',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Docs'),
  //       href: 'https://docs.pancakeswap.finance',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //   ],
  // },
]

export default config
