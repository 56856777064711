import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'
import { ChainId } from '../sdk'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getCakeAddress = () => {
  return getAddress(tokens.cake.address)
}
export const getXcharmAddress = () => {
  return getAddress(tokens.xcharm.address)
}
export const getKarmaAddress = () => { 
  return getAddress(tokens.karma.address)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWtlosAddress = () => {
  return getAddress(tokens.wtlos.address)
}
export const getBurnAddress = () => {
  return getAddress(addresses.burnAddress)
}
export const getKarmaBurnAddress = () => {
  return getAddress(addresses.burnAddress)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.charmVault)
}
export const getDojoVaultAddress = () => {
  return getAddress(addresses.dojoVault)
}
export const getDojoAddress = () => {
  return getAddress(addresses.dojo)
}
export const getStlosAddress = () => {
  return getAddress(addresses.stlos)
}
export const getTlosEscrowAddress = () => {
  return getAddress(addresses.tlosescrow)
}
export const getLendingPoolAddress = () => {
  return getAddress(addresses.LendingPool)
}
export const getLendingPoolAddressesProviderAddress = () => {
  return getAddress(addresses.LendingPoolAddressesProvider)
}
export const getOmniDexProtocolDataProviderAddress = () => {
  return getAddress(addresses.OmniDexProtocolDataProvider)
}
export const getOlpManagerAddress = () => {
  return getAddress(addresses.olpManager)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getBurnFarmOwnerAddress = () => {
  return getAddress(addresses.burnFarmOwner)
}
