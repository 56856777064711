import { Language } from 'pancakeswap-uikit'

export const EN: Language = { locale: 'en-US', language: 'English', code: 'en' }
export const DE: Language = { locale: 'de-DE', language: 'Deutsch', code: 'de' }
export const ESES: Language = { locale: 'es-ES', language: 'Español', code: 'es-ES' }
export const FR: Language = { locale: 'fr-FR', language: 'Français', code: 'fr' }

export const languages = {

  'en-US': EN,
  'de-DE': DE,
  'es-ES': ESES,
  'fr-FR': FR,
}

export const languageList = Object.values(languages)
