
const reserveTokens = [
  { symbol: "wtlos", address: "0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E" },
  //  {symbol: "cake", address: ""},
  { symbol: "karma", address: "0x730d2Fa7dC7642E041bcE231E85b39e9bF4a6a64" },
  { symbol: "stlos", address: "0xB4B01216a5Bc8F1C8A33CD990A1239030E60C905" },
  { symbol: "usdc", address: "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b" },
  { symbol: "wbtc", address: "0xf390830DF829cf22c53c8840554B98eafC5dCBc2" },
  { symbol: "eth", address: "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f" },
  { symbol: "usdt", address: "0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73" },
  //  {symbol: "bnb", address: ""},
  // {symbol: "ftm", address: "0x0"},
  { symbol: "avax", address: "0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D" },
  //  {symbol: "matic", address: ""},
]

export default reserveTokens
