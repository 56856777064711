//  This file should ONLY cotain tokens that are not included in token.ts
// Prices for these tokens are taken from coingecko
// This file provides the coingecko api call id for the tokens as this is not always the token symbol

const coingeckoids = {
  banana: {
    id: 'banana',
    },
  pops: {
    id: 'swapsicle-pops',
  },  
}
export default coingeckoids