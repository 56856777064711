import BigNumber from 'bignumber.js'
import { getOlpManagerContract, getDojoContract } from 'utils/contractHelpers'  
import { getDojoAddress } from 'utils/addressHelpers'  
import addresses from 'config/constants/contracts'

const olpManagerContract = getOlpManagerContract() 
const dojoContract = getDojoContract() 
const dojoAddress = getDojoAddress() 

export const fetchOlpPrice = async () => {  
  try {
    const olpManagerContractResponse = await olpManagerContract.getPrice(true) 
    const olpPrice = new BigNumber(olpManagerContractResponse.toString()).div(1e30) 

    return {
      isLoading: false,
      olpPrice: olpPrice.toJSON(),
    }
  } catch (error) {
    return {
      isLoading: true,
      olpPrice: null,
    }
  }
}




