import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CoingeckoDataState, AppThunk } from 'state/types'
import { fetchCoingeckoPricesUsd } from 'utils/fetchCoingeckoPricesUsd'


const initialState: CoingeckoDataState = {
  prices: null,
  coingeckoDataLoaded: false,
}

// Thunks
export const fetchCoingeckoPricesAsync = (): AppThunk =>
  async (dispatch) => {    
    const latestPrices = await fetchCoingeckoPricesUsd()    
    dispatch(setCoingeckoPrices(latestPrices))
  }

export const CoingeckoSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    setCoingeckoPrices: (state, action) => {
      state.prices = action.payload.prices   
      state.coingeckoDataLoaded = action.payload.coingeckoDataLoaded
    },
  },
})

// Actions
export const { setCoingeckoPrices } = CoingeckoSlice.actions

export default CoingeckoSlice.reducer
