
const lendingTokens = [
  { symbol: "wtlos", address: "0xebEE938fF39B6F1Ef146636a9a1BEbED6e6D13ad" },
  //  {symbol: "cake", address: ""},
  { symbol: "karma", address: "0x2B82ade7411CE7D76A771fa950F74291a42204d5" },
  { symbol: "stlos", address: "0xa49755f5985d3f3d6d902d3ED6fF0b7365896Cc2" },
  { symbol: "usdc", address: "0x2D4374ec784Cfd7a48Dc00DE29E47D1D8aEa73cf" },
  { symbol: "wbtc", address: "0x488a7ac635708875C239209E00659B59A6621E68" },
  { symbol: "eth", address: "0x0A2133003aA7192768Ba2Ea92A185b5827917e21" },
  { symbol: "usdt", address: "0xc04460B710B0b68f5DD6BF05497B1E5030Fca3B7" },
  //  {symbol: "bnb", address: ""},
  // {symbol: "ftm", address: "0x0"},
  { symbol: "avax", address: "0x5098dceDAD28Fe4f016e8F07E57701deEa3d606D" },
  //  {symbol: "matic", address: ""},
]

export default lendingTokens
