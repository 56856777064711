//  This file shold ONLY cotain tokens that are not included in token.ts
// These are tokens that are not used in the OmniDex protocol but are reported in the user portfolio
import { ChainId, Token } from '../../sdk'


export const BANANA = new Token(ChainId.MAINNET, '0x667Fd83E24Ca1D935d36717D305D54fA0CAC991C', 18, 'BANANA', 'BANANA')



const nonprotocoltokens = {
  banana: {
    symbol: 'BANANA',
    address: {
      40: '0x667Fd83E24Ca1D935d36717D305D54fA0CAC991C',
    },
    decimals: 18,
    projectLink: 'https://apeswap.finance/',
  },
  pops: {
    symbol: 'POPS',
    address: {
      40: '0x173fd7434B8B50dF08e3298f173487ebDB35FD14',
    },
    decimals: 18,
    projectLink: 'https://www.swapsicle.io/',
  }, 
}

export default nonprotocoltokens