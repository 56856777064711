import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { ReserveDataState, AppThunk } from 'state/types'
import { fetchUserLendingData, fetchLendingAccountData } from 'state/lending/fetchUserLendingData'
import { fetchReserveData } from './fetchReserveData'


const initialState: ReserveDataState = {
  reserve: null,
  userLendingBalances:[],
  healthFactor: null,
  userAccountDataLoaded: false,
  reserveDataLoaded: false,
  userReserveDataLoaded: false,
}

// Thunks
export const fetchReserveDataAsync = (): AppThunk =>
  async (dispatch) => {
    const reserve = await fetchReserveData()
    dispatch(setReserves(reserve))
  }

  export const fetchUserLendingDataAsync = (account: string): AppThunk =>
  async (dispatch) => {    
    const userReserves = await fetchUserLendingData(account)    
    dispatch(setUserLendingBalances(userReserves))
  }

  export const fetchLendingAccountDataAsync = (account: string): AppThunk =>
  async (dispatch) => {    
    const userData = await fetchLendingAccountData(account)    
    dispatch(setLendingAccountData(userData))
  }

export const ReserveSlice = createSlice({
  name: 'reserve',
  initialState,
  reducers: {
    setReserves: (state, action) => {
      state.reserve = action.payload.reserves   
      state.reserveDataLoaded = action.payload.reserveDataLoaded
    },
    setUserLendingBalances: (state, action) => {
      state.userLendingBalances = action.payload.userReserves   
      state.userReserveDataLoaded = action.payload.userReserveDataLoaded
    },
    setLendingAccountData: (state, action) => {
      state.healthFactor = action.payload.healthFactor   
      state.userAccountDataLoaded = !action.payload.isLoading
    },
  },
})

// Actions
export const { setReserves, setUserLendingBalances, setLendingAccountData } = ReserveSlice.actions

export default ReserveSlice.reducer
