import {getOmniDexProtocolDataProviderContract, getLendingPoolContract } from 'utils/contractHelpers'
import reserveTokens from 'config/constants/reserveTokens'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'

// fetches the user balances of each asset in the lending pool
// return format of each contract call is :-
// uint256: currentOTokenBalance, uint256: currentStableDebt, uint256: currentVariableDebt,
// uint256: principalStableDebt, uint256: scaledVariableDebt, uint256: stableBorrowRate,
// uint256: liquidityRate, uint40: stableRateLastUpdated, bool: usageAsCollateralEnabled
//
// This function extracts the currentOTokenBalance,currentStableDebt and currentVariableDebt values
// and returns an array of tuples 
// {underlying reserve token symbol, oTokenBalance, stableDebt and variableDebt}
export const fetchUserLendingData = async (account: string) => {
  const arrayOfCalls = []
  const contract =   getOmniDexProtocolDataProviderContract()
  
  for (let i = 0; i < reserveTokens.length; i++) {    
    arrayOfCalls.push(contract.getUserReserveData(reserveTokens[i].address,account))
  }  
  try {    
    const userReserves = await Promise.all(arrayOfCalls)
    const userAssetBalances =[]
    for (let i = 0; i < reserveTokens.length; i++) {
      const oTokenBalanceAsBigNumber = userReserves[i][0] ? new BigNumber(userReserves[i][0].toString()) : BIG_ZERO
      const stableDebtAsBigNumber = userReserves[i][1] ? new BigNumber(userReserves[i][1].toString()) : BIG_ZERO
      const variableDebtAsBigNumber = userReserves[i][2] ? new BigNumber(userReserves[i][2].toString()) : BIG_ZERO
      const reserveEntry = { symbol: reserveTokens[i].symbol, oTokenBalance: oTokenBalanceAsBigNumber.toJSON(), stableDebt: stableDebtAsBigNumber.toJSON(), variableDebt: variableDebtAsBigNumber.toJSON() }
      userAssetBalances.push(reserveEntry)
    }    
    return {
      userReserves: userAssetBalances,
      userReserveDataLoaded: true,
    }
  } catch (error) {    
    return {
      userReserves: null,
      userReserveDataLoaded: false,
    }
  }
}

export const fetchLendingAccountData = async (account: string) => {  
  const contract =   getLendingPoolContract()

  try {
    const userContractResponse = await contract.getUserAccountData(account) 
    const healthFactorReponse = userContractResponse.healthFactor   
    const healthFactor = healthFactorReponse ? new BigNumber(healthFactorReponse.toString()) : BIG_ZERO

    return {
      isLoading: false,
      healthFactor: healthFactor.toJSON(),
    }
  } catch (error) {
    return {
      isLoading: true,
      healthFactor: null,
    }
  }
}