import { getBep20Contract } from 'utils/contractHelpers' 
import tokens from 'config/constants/tokens'
import nonProtocolTokens from 'config/constants/nonprotocoltokens'
import tradingTokens from 'config/constants/tradingtokens'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'

export const fetchWalletData = async (account: string) => {
  const nonTlosTokenKeys = Object.keys(tokens).filter((key) => key !== 'tlos')  
  const tradingTokenKeys = Object.keys(tradingTokens).filter((key) => !nonTlosTokenKeys.includes(key))    
  const nonProtocolTokenKeys = Object.keys(nonProtocolTokens).filter((key) => !nonTlosTokenKeys.includes(key))    
  // create an array of calls to get wallet token balances
  const arrayOfCalls = []
  const arrayOfSymbols = []
  for (let i = 0; i < nonTlosTokenKeys.length; i++){
    const addressObject = tokens[Object.keys(tokens)[i+1]].address
    const tokenAddress = addressObject[Object.keys(addressObject)[0]]
    const contract = getBep20Contract(tokenAddress)
    arrayOfCalls.push(contract.balanceOf(account))
    arrayOfSymbols.push(tokens[Object.keys(tokens)[i+1]].symbol)
  }
  for (let i = 0; i < tradingTokenKeys.length; i++){
    const addressObject = tradingTokens[Object.keys(tradingTokens)[i]].address
    const tokenAddress = addressObject[Object.keys(addressObject)[0]]
    const contract = getBep20Contract(tokenAddress)
    arrayOfCalls.push(contract.balanceOf(account))
    arrayOfSymbols.push(tradingTokens[Object.keys(tradingTokens)[i]].symbol)
  }
  for (let i = 0; i < nonProtocolTokenKeys.length; i++){
    const addressObject = nonProtocolTokens[Object.keys(nonProtocolTokens)[i]].address
    const tokenAddress = addressObject[Object.keys(addressObject)[0]]
    const contract = getBep20Contract(tokenAddress)
    arrayOfCalls.push(contract.balanceOf(account))
    arrayOfSymbols.push(nonProtocolTokens[Object.keys(nonProtocolTokens)[i]].symbol)
  }

  // now execute all the calls symultaneously and create an array of balances
  try {
    const walletResponses = await Promise.all(arrayOfCalls)
    const walletBalances = []
    for (let i = 0; i < arrayOfCalls.length; i++){
      const tokenBalanceAsBigNumber = walletResponses[i] ? new BigNumber(walletResponses[i].toString()) : BIG_ZERO 
      if(tokenBalanceAsBigNumber.gt(0)) {
        const walletEntry = {token: arrayOfSymbols[i], tokenBalance: tokenBalanceAsBigNumber.toJSON()}
        walletBalances.push(walletEntry)
      }
    }
    
    return { 
      wallet: walletBalances,
      walletDataLoaded: true,
    } 
  } catch (error) {
    return {
      wallet: null,
      walletDataLoaded: false,
    }
  }
}

