import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { PortfolioDataState, AppThunk } from 'state/types'
import { fetchWalletData } from './fetchPortfolio'

const initialState: PortfolioDataState = {
  wallet: [],
  walletDataLoaded: false,
}

// Thunks
export const fetchWalletDataAsync = (account: string): AppThunk =>
  async (dispatch) => {
    const portfolio = await fetchWalletData(account)
    dispatch(setPortfolio(portfolio))
  }

export const PortfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setPortfolio: (state, action) => {
      const { wallet, walletDataLoaded } = action.payload ?? {};
      if (wallet != null && walletDataLoaded != null) {
        state.wallet = wallet;
        state.walletDataLoaded = walletDataLoaded;
      }
    }
//    setPortfolio: (state, action) => {
//      state.wallet = action.payload.wallet     
//      state.walletDataLoaded = action.payload.walletDataLoaded
//    },
  },
})

// Actions
export const { setPortfolio } = PortfolioSlice.actions

export default PortfolioSlice.reducer
