import nonProtocolTokens from 'config/constants/nonprotocoltokens'
import coingeckoids from 'config/constants/coingeckoids'

export async function fetchCoingeckoPricesUsd(): Promise<any> {
  // here we need to get the coingecko call ids for each non protocol token 
  const nonProtocolTokenIds = Object.keys(nonProtocolTokens)
  const coingeckoCallIds = nonProtocolTokenIds.map((thisToken) => { return coingeckoids[thisToken].id});
  // These ids are then used to build a set of api calls and return the price
  const apiRoot = 'https://api.coingecko.com/api/v3/coins/'
  const apiParams = '?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false'
 
  try{
    const apiCalls = coingeckoCallIds.map(id => `${apiRoot}${id}${apiParams}`)
    const responses = await Promise.all(apiCalls.map(call => fetch(call)));
    const data = await Promise.all(responses.map(response => response.json()))
    const priceDataArray = data.map((body, index) => {
      const id = nonProtocolTokenIds[index]
      const symbol = nonProtocolTokens[id].symbol
      const tokenPrice = (body.market_data.current_price.usd).toString()
      return { id, symbol, tokenPrice }
    })
    const dataLoaded = true    
    return {prices: priceDataArray, coingeckoDataLoaded: dataLoaded}  // need to return an array of PriceEntry (id, symbol, price as strings)
  }
  catch (error){
    const priceDataArray =[]
    const dataLoaded = false
    return {priceDataArray, dataLoaded}
  }  
}
